import React, { useState } from "react";
import styled from "styled-components";
import { graphql, PageProps } from "gatsby";

import AllUpdates from "@components/updates/allUpdates";
import PressUpdates from "@components/updates/pressUpdates";
import { Container, GridContainer, H1, H2, TextButton } from "@util/standard";
import TeamUpdates from "@components/updates/teamUpdates";
import { MOBILE_BREAKPOINT } from "@util/constants";
import SEO from "@components/shared/seo";
import { Maybe, Query, SanityBlogs } from "@graphql-types";
import Preview from "@components/shared/preview";
import { FluidObject } from "gatsby-image";

const filters = ["All", "Press", "Team"];

const Wrapper = styled(Container)`
  // margin: 80px auto;
  flex-direction: column;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 30px auto;
    width: 90%;

    h1 {
      font-size: 50px;
      margin: 100px auto 50px auto;
    }
  }
`;

interface Props extends PageProps {
  data: Query;
}

export default function UpdatesLanding({ data }: Props) {
  const { sanityUpdatesLanding, allSanityBlogs, allSanityBlogCategory } = data;
  const [filtered, setFiltered] = useState("All");

  const FilterButtons = () => {
    return (
      <Container width="100%" margin="0 0 20px 0">
        <TextButton
          onClick={() => setFiltered("All")}
          margin="0 20px 20px 0"
          underlined={filtered === "All"}
        >
          All
        </TextButton>
        {allSanityBlogCategory.nodes.map((filter) => {
          const handleClick = () => {
            if (filter.title == null) return;
            setFiltered(filter?.title);
          };
          return (
            <TextButton
              key={filter._key}
              onClick={handleClick}
              margin="0 20px 20px 0"
              underlined={filter.title === filtered}
            >
              {filter.title}
            </TextButton>
          );
        })}
      </Container>
    );
  };

  return (
    <Wrapper width="90%">
      <SEO seoData={sanityUpdatesLanding?.seo} slug="updates" />
      <H1 margin="250px 0 50px 0" fontSize={100}>
        {sanityUpdatesLanding?.title}
      </H1>
      <FilterButtons />
      <Updates data={allSanityBlogs.nodes} filterTitle={filtered} />
    </Wrapper>
  );
}

interface FilterProps {
  data: Maybe<SanityBlogs>[];
  filterTitle: string;
}
const Updates = ({ data, filterTitle }: FilterProps) => {
  const filteredBlogs = data.filter((blog) => {
    if (blog?.category == null) return null;
    if (filterTitle === "All") return blog;
    return blog?.category.title === filterTitle;
  });
  return (
    <GridContainer repeat={3} columnGap="20px" rowGap="20px">
      {filteredBlogs.map((blog) => {
        if (blog == null) {
          return null;
        }

        return (
          <Preview
            key={blog._id}
            title={blog.title}
            category={blog.category?.title}
            image={blog.image?.asset?.fluid as FluidObject}
            mobileImage={blog.mobileImage}
            slug={`updates/${blog.slug?.current}`}
          />
        );
      })}
    </GridContainer>
  );
};

export const query = graphql`
  query UpdatesQuery {
    sanityUpdatesLanding {
      title
      seo {
        pageDescription
        pageKeyWords
        pageTitle
        jsonLD {
          _key
          _type
          language
          filename
          code
        }
        slug {
          current
        }
        ogImage {
          asset {
            url
          }
        }
      }
    }
    allSanityBlogs(sort: { fields: publishDate, order: DESC }) {
      nodes {
        ...sanityBlogsPreview
      }
    }
    allSanityBlogCategory(sort: { fields: title, order: ASC }) {
      nodes {
        title
        _key
      }
    }
  }
`;
