import React, { useState } from "react";
import { FluidObject } from "gatsby-image";
import styled from "styled-components";
import { navigate } from "gatsby";

import { AnimationContainer, Image, Link, LinkButton } from "@sub";
import {
  P,
  H3,
  Container,
  DesktopContainer,
  MobileContainer,
} from "@util/standard";
import { Maybe, SanityImageWithMeta } from "@graphql-types";
import {
  colorsRGB,
  LAPTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
  TWENTY_INCH_SCREEN,
  ANIMATION_TRANSITION,
  animationType,
} from "@util/constants";

const Wrapper = styled(Container)`
  width: auto;
  margin: 0;
  flex-direction: column;
  position: relative;
  cursor: url("/cursorClick.svg"), auto;

  h3 {
    margin: 20px 0 0 0 !important;
    font-size: 22px;
  }
  a,
  p {
    margin: 20px 0 0 0 !important;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    a,
    p {
      font-size: 14px;
      margin: 20px 0 0 0;
    }

    h3 {
      margin-top: 20px;
    }
  }
`;

const ImageContainer = styled(Container)<{}>`
  width: 100%;
  margin: 0;
  position: relative;
  height: 400px;
  overflow: hidden;

  @media only screen and (max-width: ${TWENTY_INCH_SCREEN}px) {
    height: 400px;
  }

  @media only screen and (max-width: ${LAPTOP_BREAKPOINT}px) {
    height: 300px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    height: 250px;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 300px;
  }
`;

export const Overlay = styled.div<{ hover: boolean }>`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  transition: 0.5s;
  overflow: hidden;
  transition: background-color ${ANIMATION_TRANSITION}s linear;
  &:hover {
    background-color: ${colorsRGB.white()};
  }
`;

const StyledLinkButton = styled(Link)`
  margin: 30px 0 0 0;
  display: flex;
`;

interface Props {
  id?: string | null;
  title: Maybe<string> | undefined;
  image?: FluidObject;
  mobileImage?: Maybe<SanityImageWithMeta>;
  description?: string;
  slug?: string | null;
  isBlog?: boolean;
  category?: string | null;
}

const Preview = (props: Props) => {
  const { image, category, title, slug, mobileImage } = props;
  const [hover, setHover] = useState(false);

  const readMoreText = "Read More";

  return (
    <AnimationContainer animationType={animationType.slideInBottom}>
      <Wrapper
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => navigate(`/${slug}/`)}
      >
        {image && mobileImage && (
          <>
            <DesktopContainer
              flexDirection="column"
              width="100%"
              position="relative"
            >
              <ImageContainer>
                <Image
                  data={image}
                  fluid={image as FluidObject}
                  objectFit="cover"
                />
              </ImageContainer>
            </DesktopContainer>
            <MobileContainer
              flexDirection="column"
              width="100%"
              position="relative"
            >
              <ImageContainer>
                <Image
                  data={mobileImage}
                  fluid={mobileImage.asset?.fluid as FluidObject}
                  isBackground
                  width="100%"
                  backgroundStyle={{
                    width: "100%",
                  }}
                />
              </ImageContainer>
            </MobileContainer>
          </>
        )}
        {image && !mobileImage && (
          <ImageContainer>
            <Image
              fluid={image}
              data={image}
              isBackground
              backgroundStyle={{
                width: "100%",
              }}
            />
          </ImageContainer>
        )}
        {hover && <Overlay hover={hover} />}

        {category && (
          <P fontSize={14} margin="20px auto 10px 0">
            {category}
          </P>
        )}
        <H3 fontSize={20} noMargin>
          {title}
        </H3>

        <LinkButton
          bold
          margin="15px 0 0 0"
          color="flightBlue"
          pathTo={`/${slug}/`}
          hover={hover}
          fontSize={14}
        >
          {readMoreText}
        </LinkButton>
      </Wrapper>
    </AnimationContainer>
  );
};

export default Preview;
